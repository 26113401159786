/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import { Query } from '@firebase/firestore-types';
import { useEffect, useMemo } from 'react';

import { useConfig } from '../../config/config.context';
import useEvent from '../../core/hooks/useEvent.hook';
import { useMe } from '../../profile/hooks';
import { useSubscription } from '../../pubsub/hooks/pubsub.hook';
import store from '../../shared/Store';
import { MINUTE } from '../../utils/timeUtils';
import { useFirebase } from '../../utils/useFirebase';

export const useGamification = (callback: (type: string, message: any) => void): any => {
  const fb = useFirebase() as any;
  const me = useMe();
  const config = useConfig();
  const { enabled, websockets, unlockTimeToleranceMinutes } = config?.gamification || {};
  const subscription = useMemo(
    () => ({
      topic: `/${store.eventId}/gamification/users/${me?._id}`,
      type: 'gamification:achievement:unlock',
      callback,
    }),
    [me?._id, callback],
  );

  const unlockedAchievementsRef = useMemo(() => {
    if (!me?._id) return null;
    return fb
      ?.firestore()
      .collection('platform')
      .doc(store.eventId)
      .collection('users')
      .doc(me?._id)
      .collection('unlocked-achievements') as Query;
  }, [fb, me?._id]);

  useEffect(() => {
    if (!unlockedAchievementsRef) return undefined;

    return unlockedAchievementsRef.where('hasShown', '==', false).onSnapshot((data) => {
      data.docChanges().forEach((docChange) => {
        if (docChange.type === 'added') {
          const doc = docChange.doc.data();
          if (!doc.hasShown) {
            // Notify if recent, less than 10 minutes ago... !!
            const now = Date.now();
            if (now < doc.unlockedAt + (unlockTimeToleranceMinutes || 10) * MINUTE) {
              callback('achievement-unlocked', { payload: doc });
            }

            // Update hasShown to true
            docChange.doc.ref.update({ hasShown: true });
          }
        }
      });
    });
  }, [unlockedAchievementsRef, callback]);

  return useSubscription(subscription, enabled && websockets !== 'firestore');
};
